import firebase from 'firebase'
const config = {
    apiKey: `${process.env.REACT_APP_FIR_KEY}`,
    authDomain: "loopthemessenger.firebaseapp.com",
    databaseURL: "https://loopthemessenger.firebaseio.com",
    projectId: "loopthemessenger",
    storageBucket: "loopthemessenger.appspot.com",
    messagingSenderId: "478101586327",
    appId: "1:478101586327:web:325431fe38ac73d2b8c4d2",
    measurementId: "G-XRH7F1GZRL"
};
firebase.initializeApp(config);
export default firebase;