import React from 'react';
import edit from "./assets/img/edit.png";

class Two extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="showcase-wrapper slate">
                <div className={"half-wrapper"}>
                    <img src={edit} className="mockup" alt="profile-page" />
                </div>
                <div className={"half-wrapper"}>
                    <div className={"showcase-section"}>
                        <h1>an ever-improving design suite</h1>
                        <div className={"showcase-spacer"}/>
                        <p>
                            no face filters, no predefined layouts,
                            we're building design tools to be infinitely customizable,
                            so your posts can be as unique as you are.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

}

export default Two;
