import React from 'react';
import './assets/css/Showcase.css';
import loop from "./assets/img/new_loop.png";

class Four extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div className="showcase-wrapper slate">
                <div className={"half-wrapper"}>
                    <img src={loop} className="mockup" alt="profile-page" />
                </div>
                <div className={"half-wrapper"}>
                    <div className={"showcase-section"}>
                        <h1>sharing the way it should be: with people</h1>
                        <div className={"showcase-spacer"}/>
                        <p>
                            your loops are like mailing lists, except, well, they're cool.
                            share ownership so your social groups can quickly share with you,
                            or make them public so anyone can subscribe to different content you put out.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

}

export default Four;
