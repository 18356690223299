import React from 'react';
import logo from './assets/img/logo@2x.png';
import './assets/css/Legal.css';
import Background from "./Background";
import Privacy from "./Privacy";
import Terms from "./Terms";
import { useHistory } from "react-router-dom";

class Legal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 0
        };
        this.toggleActive = this.toggleActive.bind(this);
        this.handleLogoClick = this.handleLogoClick.bind(this);
    }

    toggleActive(){
        if (this.state.active == 0) {
            this.setState({
                active: 1
            })
        } else {
            this.setState({
                active: 0
            })
        }
    }

    handleLogoClick(event){
        useHistory().push("");
    }


    render() {
        var privacy, terms, document;
        if (this.state.active == 0) {
            privacy = "selected";
            terms = "";
            document = <Privacy />
        } else {
            privacy = "";
            terms = "selected";
            document = <Terms />
        }
        return (
            <div className="legal">
                <Background />
                <div className={"legal-logo-wrapper"}>
                    <a href={"/"}>
                        <img src={logo} className="logo" alt="logo" />
                    </a>

                </div>
                <div className={"segment-toggle"}>
                    <div className={privacy} onClick={this.toggleActive}>PRIVACY POLICY</div>
                    <div className={terms} onClick={this.toggleActive}>TERMS OF SERVICE</div>
                </div>
                <div className={"description"}>
                    {document}
                </div>
            </div>
        );
    }
}

export default Legal;
