import React from 'react';
import './assets/css/App.css';
import Mailing from "./Mailing";
import Background from "./Background";
import One from "./One"
import Two from "./Two"
import Three from "./Three";
import Four from "./Four";
import Tips from "./Tips";
import Footer from "./Footer";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'scroll': 0,
            'scrolling': false,
            'finished': false,
            'startY': 0
        };
    }

    render() {
        return (
            <div className="App">
                <One />
                <Two />
                <Three />
                <Four />
                <Tips />
                <Mailing />
                <Footer />
                <Background />
            </div>
        );
    }
}

export default App;
