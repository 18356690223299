import React from 'react';
import profile from './assets/img/profile.png'
import logo from './assets/img/logo@1x.png';

class One extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className={"showcase-wrapper lace"}>
                <div className={"half-wrapper right"}>
                    <div className={"showcase-header"}>
                        <h1>loop</h1>
                        <div className={"showcase-spacer"}/>
                        <p>
                            don't just share.<br/>
                            share with.
                        </p>
                        <h4>
                            coming feb 2020.
                        </h4>
                    </div>
                </div>
                <div className={"half-wrapper"}>
                    <img src={profile} className="mockup" alt="profile-page" />
                </div>
            </div>
        )
    }

}

export default One;
