import React from 'react';
import one from './assets/img/tips-1.png'
import two from './assets/img/tips-2.png'
import three from './assets/img/tips-3.png'
import four from './assets/img/tips-4.png'

class Tips extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'position': 0
        };
        this.handleLeft = this.handleLeft.bind(this);
        this.handleRight = this.handleRight.bind(this);
        this.wrapper = React.createRef();
        this.mockups = React.createRef()
    }

    handleLeft(event){
        console.log("HANDLE LEFT");
        let newPosition = Math.max(0, this.state.position-1);
        this.updatePosition(newPosition);
    }

    handleRight(event){
        let newPosition = Math.min(3, this.state.position+1);
        this.updatePosition(newPosition);
    }

    updatePosition(newPosition){
        if (this.state.position !== newPosition) {
            let rowWidth = this.mockups.current.getBoundingClientRect().width;
            this.setState({
                'position': newPosition
            });
            this.mockups.current.scrollTo({top: 0, left: rowWidth*newPosition, behavior: 'smooth' });
        }
    }

    render() {
        let title, body;
        let a, b, c, d = "";
        if (this.state.position === 0){
            title = "your social media, your rules";
            body = "every post is a chat where you set the rules of engagement.";
            a = "active";
        } else if (this.state.position === 1){
            title = "privacy and control at your fingertips";
            body = "delegate post ownership and set the rules for sharing with a simple tap.";
            b = "active";
        } else if (this.state.position === 2){
            title = "social media built for you, not advertisers";
            body = "a clean, simple user experience built to give you everything you need, and when we do introduce ads, it won't be by tracking or targeting your data, we have a revenue plan, compromising your privacy isn't in it.";
            c = "active";
        } else {
            title = "same same, but different";
            body = "all the features you're used to, no learning curve necessary.";
            d = "active";
        }
        let pagination = <div className={"mockup-pagination-wrapper"}>
            <div className={"mockup-button-wrapper"}>
                <div className={`mockup-button ${a}`} onClick={() => this.updatePosition(0)}/>
            </div>
            <div className={"mockup-button-wrapper"}>
                <div className={`mockup-button ${b}`} onClick={() => this.updatePosition(1)}/>
            </div>
            <div className={"mockup-button-wrapper"}>
                <div className={`mockup-button ${c}`} onClick={() => this.updatePosition(2)}/>
            </div>
            <div className={"mockup-button-wrapper"}>
                <div className={`mockup-button ${d}`} onClick={() => this.updatePosition(3)}/>
            </div>
        </div>;
        let before, after;
        if (window.innerWidth > 575) {
            before = pagination;
            after = null;
        } else {
            before = null;
            after = pagination;
        }
        return (
            <div className="showcase-wrapper lace" ref={this.wrapper}>
                <div className={"half-wrapper right"}>
                    <div className={"showcase-section"}>
                        {before}
                        <h1>{title}</h1>
                        <div className={"showcase-spacer"}/>
                        <p>
                            {body}
                        </p>
                        {after}
                    </div>
                </div>

                <div className={"half-wrapper"}>
                    <div className={"mockups-row"} ref={this.mockups}>
                        <div className={"mockup-row-cell"}>
                            <div className={"mockup-img-wrapper"}>
                                <img src={one} className="mockup" alt="profile-page" />
                            </div>
                        </div>
                        <div className={"mockup-row-cell"}>
                            <div className={"mockup-img-wrapper"}>
                                <img src={two} className="mockup" alt="profile-page" />
                            </div>
                        </div>
                        <div className={"mockup-row-cell"}>
                            <div className={"mockup-img-wrapper"}>
                                <img src={three} className="mockup" alt="profile-page" />
                            </div>
                        </div>
                        <div className={"mockup-row-cell"}>
                            <div className={"mockup-img-wrapper"}>
                                <img src={four} className="mockup" alt="profile-page" />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        )
    }

}

export default Tips;

{/*<div className={"description"}>*/}
{/*    <p>*/}
{/*        Dynamic, human-centric group messaging bringing intentionality back to sharing online. That was a mouthful.*/}
{/*    </p>*/}
{/*    <p>*/}
{/*        To find out what all that means, join our mailing list so you can be in the community from Day 1.*/}
{/*    </p>*/}
{/*    <h2>Coming to iOS, Fall 2019.</h2>*/}
{/*</div>*/}
