import React from 'react';
import './assets/css/Showcase.css';
import send from "./assets/img/send.png";

class Three extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div className="showcase-wrapper lace">
                <div className={"half-wrapper right"}>
                    <div className={"showcase-section"}>
                        <h1>every post deserves its own audience</h1>
                        <div className={"showcase-spacer"}/>
                        <p>
                            no more sharing into the void, pick the audience for every story, every post.

                        </p>
                    </div>
                </div>
                <div className={"half-wrapper"}>
                    <img src={send} className="mockup" alt="profile-page" />
                </div>

            </div>
        )
    }

}

export default Three;