import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Legal from "./Legal";
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'

// import { BrowserRouter as Router, Route } from "react-router-dom";

const routing = (
    <Router>
        <div>
            <Route exact path="/" component={App} />
            <Route path="/legal">
                <Legal />
            </Route>

        </div>
    </Router>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
