import React from 'react';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className={"footer-wrapper lace"}>
                <div className={"footer-half"}>
                    copyright loop social inc. 2020
                </div>
                <div className={"footer-half"}>
                    <a href={"legal"}>
                        boring legal stuff
                    </a>
                </div>
            </div>
        )
    }

}

export default Footer;

