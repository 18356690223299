import './assets/css/Background.css';
import React from 'react';
import backgroundSvg from './assets/img/background.svg';

class Background extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className={"background-wrapper"}>
                <div id="Clouds">
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Foreground"></div>
                    <div className="Cloud Background"></div>
                    <div className="Cloud Background"></div>
                </div>
            </div>
     )
    }
}

export default Background