import React from 'react';
import firebase from './firebase.js';
import './assets/css/Mailing.css';

class Mailing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            emailValid: false,
            nameValid: false,
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        this.setState({
            [target.name]: target.value
        }, (e) => {
            if (target.name === "email") {
                this.setState({
                    emailValid: false
                });
                this.validateEmail()
            } else {
                this.setState({
                    nameValid: false
                });
                this.validateName()
            }
        });

    }

    validateEmail(){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validation = re.test(String(this.state.email).toLowerCase());
        this.setState({
            emailValid: validation
        })
    }

    validateName(){
        let validation = /^[A-Za-z\s]+$/.test(this.state.name);
        this.setState({
            nameValid: validation
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.nameValid && this.state.emailValid){
            let datum = {
                name: this.state.name,
                email: this.state.email
            };
            let addDoc = firebase.firestore().collection('mailing')
                .add(datum)
                .then(ref => {
                    this.setState({
                        name: '',
                        email: '',
                        emailValid: false,
                        nameValid: false,
                        submitted: true
                    });
                });
        }

    }

    render() {
        var nameCheck;
        var emailCheck;
        if (this.state.nameValid) {
            nameCheck = <div className={"check"}/>
        }
        if (this.state.emailValid) {
            emailCheck = <div className={"check"}/>
        }
        var submit;
        if (this.state.submitted){
            submit = <div className={"mailing-success"}>Welcome to the community.</div>
        }
        let buttonState = "";
        if (this.state.emailValid && this.state.nameValid){
            buttonState = "button-ready"
        }
        return (
            <div className={"showcase-wrapper lace"}>
                <div className="mailing-wrapper">
                    <h2>
                        join our mailing list to be first in line
                    </h2>
                    <p>
                        (and get the username you've always wanted)
                    </p>
                    <form className={"mailing-form"} onSubmit={this.handleSubmit}>
                        <div className={"input-wrapper"}>
                            <input type="text"
                                   name="name"
                                   placeholder="Name"
                                   onFocus={(e) => e.target.placeholder = ""}
                                   onBlur={(e) => e.target.placeholder = "Name"}
                                   onChange={this.handleChange}
                                   value={this.state.name} />
                            {nameCheck}
                        </div>

                        <div className={"input-wrapper"}>
                            <input type="text"
                                   name="email"
                                   placeholder="Email"
                                   onFocus={(e) => e.target.placeholder = ""}
                                   onBlur={(e) => e.target.placeholder = "Email"}
                                   onChange={this.handleChange}
                                   value={this.state.email} />
                            {emailCheck}
                        </div>
                        <button className={buttonState}>Get In The Loop</button>
                    </form>
                    {submit}
                </div>
            </div>


        )
    }

}

export default Mailing;

